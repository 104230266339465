import styled from "styled-components/macro";

export const Container = styled.div``;

export const TwoColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: 50px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 4fr;
    margin-top: 80px;
  }
`;

export const Side = styled.div`
  display: grid;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const Main = styled.main``;

export const MainHeader = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  padding: 0 15px;
`;

export const Banner = styled.div`
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const BannerContainer = styled.div`
  background-position: top center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-image: url(https://winecellar.vn/wp-content/uploads/2022/11/hinh-anh-nha-san-xuat-ruou-vang.jpeg);
`;

export const Breadcrumb = styled.div`
  z-index: 1;
  position: absolute;
  right: 73px;
  top: 153px;
  width: 332px;
  display: flex;
  flex-direction: column;
  color: white;
`;
