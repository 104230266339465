import styled from 'styled-components/macro';
import flag from './nl-flag.png';

export const Container = styled.div`
  background-color: #fff;
  left: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
  box-shadow: 1px 1px 10px rgba(0,0,0,.15);
  width: 100%;
  height: 80px;
  z-index: 9;
  background-color: #990d23;
`;

export const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;

  img {
    width: 100px;
    height: 55px;
  }
`;

export const Description = styled.div`
  padding: 10px 10px 0 0px;
  width: 80%;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    padding: 10px 5px 0 0px;
  }

  h4 {
    font-size: 12px;
    margin: 0;
  }

  p {
    font-size: 12px;

    margin: 0;
  }

  a:link,
  a:visited {
    color: #000;
  }
`;
