import { useEffect } from "react";

import Loader from "components/Loader";
import Header from "components/Header";
import Filter from "components/Filter";
import Products from "components/Products";

import { useProducts } from "contexts/products-context";

import * as S from "./style";

function App() {
  const { isFetching, products, fetchProducts } = useProducts();

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header />
      <div
        style={{
          height: "400px",
          backgroundColor: "#f6f6f6",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <S.BannerContainer>
          <S.Banner />
          <S.Breadcrumb>
            <h1>Rượu Vang</h1>
            <p>
              Trải nghiệm hương vị tuyệt vời với đa dạng dòng vang, từ vang giá
              tốt uống hàng ngày đến rượu vang cao cấp được cả thế giới ngưỡng
              mộ. Mỗi chai vang đều được lựa chọn tỉ mỉ, từ những nhà sản xuất
              danh tiếng hàng đầu.
            </p>
          </S.Breadcrumb>
        </S.BannerContainer>
      </div>
      <S.TwoColumnGrid>
        <S.Side>
          <Filter />
        </S.Side>
        <S.Main>
          <S.MainHeader>
            <p>{products?.length} Kết quả tìm thấy</p>
          </S.MainHeader>
          <Products products={products} />
        </S.Main>
      </S.TwoColumnGrid>
    </S.Container>
  );
}

export default App;
