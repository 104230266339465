import formatPrice from 'utils/formatPrice';
import { IProduct } from 'models';


import * as S from './style';

interface IProps {
  product: IProduct;
}

const Product = ({ product }: IProps) => {
  const {
    sku,
    title,
    price,
    installments,
    currencyId,
    currencyFormat,
    isFreeShipping,
    description
  } = product;

  const formattedPrice = formatPrice(price, currencyId);
  let productInstallment;

  if (installments) {
    const installmentPrice = price / installments;

    productInstallment = (
      <S.Installment>
        <span>or {installments} x</span>
        <b>
          {currencyFormat}
          {formatPrice(installmentPrice, currencyId)}
        </b>
      </S.Installment>
    );
  }


  return (
    <S.Container  sku={sku} tabIndex={1}>
      {isFreeShipping && <S.Stopper>Free shipping</S.Stopper>}
      <S.Image alt={title} />
      <S.Title>{title}</S.Title>
      <S.ProductDescription>{description}</S.ProductDescription>
      <S.Price>
        <S.Val>
          <b>{price.toLocaleString('en-US', {style : 'currency', currency : 'VND'})}</b>
        </S.Val>
        {productInstallment}
      </S.Price>
      <S.BuyButton tabIndex={-1}>
        Mua ngay
      </S.BuyButton>
    </S.Container>
  );
};

export default Product;
