import logo from './logo.png';
import * as S from './style';

const Header = () => (
  <S.Container>
    <S.Thumbnail>
      <img
        alt="winestore"
        src={logo}
      />
    </S.Thumbnail>
    <S.Description>
      
    </S.Description>
  </S.Container>
);

export default Header;
